import React, { Component } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import BlogPreviewComponent from "../components/BlogPreviewComponent"
import { graphql } from "gatsby"

export class BlogIndex extends Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const blogs = this.props.data.allContentfulPortfolio.edges
    console.log(blogs)

    return (
      <div>
        <Helmet title={siteTitle} />
        <Layout>
          <div className="container">
            <div className="text-center pad-40">
              <h2 className="section-title">ブログ一覧</h2>
            </div>
            <ul className="article-list row">
              {blogs.map(({ node }) => {
                return (
                  <li key={node.slug} className="col-lg-6">
                    <BlogPreviewComponent blog={node} />
                  </li>
                )
              })}
            </ul>
          </div>
        </Layout>
      </div>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
        description
      }
    }

    allContentfulPortfolio {
      edges {
        node {
          author
          createdAt(formatString: "MMMM DD, YYYY")
          blogTitle
          blogDescription {
            blogDescription
          }
          slug
          blogImage {
            file {
              url
            }
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`
