import React, { Component } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { FaArrowRight } from "react-icons/fa/"
import { FaCalendar } from "react-icons/fa/"
import { FaTag } from "react-icons/fa/"
import { FaUser } from "react-icons/fa/"

// export default ({ blog}) => (

//     <article className="blog-listing" key={blog.slug}>
//     <div className="entry-meta-content">
//     <div className="entry-media">
//       <Img
//         fluid={blog.blogImage.fluid}
//         backgroundColor={"#f4f8fb"}
//       />
//     </div>
//       <h2 className="entry-title">
//         <Link to={blog.slug}>{blog.blogTitle}</Link>
//       </h2>
//       <div className="entry-content">
//         <p>{blog.blogShortDesc}</p>
//       </div>
//     </div>

//     <div className="entry-content-bottom">
//       <Link to={blog.slug} className="entry-read-more">
//         <span />
//         Read More
//       </Link>
//     </div>
//   </article>
// )

class BlogPreviewComponent extends Component {
  render() {
    const MAX_LENGTH = 100
    const MAX_LENGTH_Title = 80
    const { blog } = this.props

    const Wrapper = styled.div`
      .meta {
        display: flex;
        flex-flow: row wrap;
        font-size: 0.8em;
        align-item: right;
        background: transparent;

        span {
          
          display: flex;
          text-transform: uppercase;
          color: #a57d33;
          padding: 5px 20px;
}
        }
      }
    `

    return (
      <Wrapper>
        <article className="blog-listing" key={blog.slug}>
          <div className="entry-meta-content">
            <div className="entry-media">
              <Img
                fluid={blog.blogImage.fluid}
                backgroundColor={"#f4f8fb"}
                loading="eager"
                fadeIn={false}
              />
            </div>
            <h2 className="entry-title">
              <Link to={`/${blog.slug}`}>
                {" "}
                {blog.blogTitle > MAX_LENGTH
                  ? blog.blogTitle
                  : blog.blogTitle.substring(0, MAX_LENGTH_Title)}{" "}
              </Link>
            </h2>
            <div></div>
            <div className="entry-content">
              {/* <p>{blog.blogShortDesc}</p>    */}

              {blog.blogDescription > MAX_LENGTH
                ? blog.blogDescription.blogDescription
                : blog.blogDescription.blogDescription.substring(
                    0,
                    MAX_LENGTH
                  ) + "..."}
            </div>
          </div>
          <p className="meta">
            <span>
              <FaCalendar size={18} />
              {blog.createdAt}
            </span>
            <span>
              <FaUser size={18} />
              {blog.author}
            </span>
          </p>

          <div className="entry-content-bottom">
            <Link to={`/${blog.slug}`} className="entry-read-more">
              <span />
              さらに読む
            </Link>
          </div>
        </article>
      </Wrapper>
    )
  }
}

export default BlogPreviewComponent
